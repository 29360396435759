<script setup lang="ts">
import { computed } from 'vue'
import { AnchorIcon, ArrowWaveRightDownIcon, CalendarIcon, LivePhotoIcon, MapPinsIcon, MessageIcon, UsersIcon } from 'vue-tabler-icons'
import { useDisplay } from 'vuetify'
import type { SidebarMenu } from './left-menu'
import NavFooter from '~/components/main/sidebar/navigation/NavFooter.vue'
import NavItem from '~/components/main/sidebar/navigation/NavItem.vue'
import NavGroup from '~/components/main/sidebar/navigation/NavGroup.vue'
import { useSidebarStore } from '~/stores/sidebar'

const leftMenu: SidebarMenu[] = [
  { header: 'Most important' },
  {
    title: 'Invites',
    icon: ArrowWaveRightDownIcon,
    to: '/invites',
  },
  {
    header: 'People',
  },
  {
    title: 'Chat',
    icon: MessageIcon,
    to: '/chat',
    chip: 121,
    chip2: 89,
    chip2Icon: UsersIcon,
  },
  { header: 'Plan' },
  {
    title: 'My activities',
    icon: LivePhotoIcon,
    children: [
      {
        title: 'Active',
        icon: MapPinsIcon,
        to: '/active',
        nested: true,
      },
      {
        title: 'Paused',
        icon: AnchorIcon,
        to: '/finished',
        nested: true,
      },
    ],
  },
  {
    title: 'Calendar',
    icon: CalendarIcon,
    to: '/calendar',
    chip: 2,
  },
]

const sidebar = useSidebarStore()
const { mdAndUp } = useDisplay()

const railMini = computed<boolean>(() => mdAndUp && sidebar.sidebar_left_mini)

/*
const expandOnHover = ref(true)

function hideSidebar() {
  expandOnHover.value = false
  sidebar.SIDEBAR_L_TOGGLE()
  setTimeout(() => { expandOnHover.value = true }, 1_000)
}
 */
</script>

<template>
  <v-navigation-drawer
    v-model="sidebar.sidebar_left"
    color="surface"
    :rail="railMini" :expand-on-hover="true"
    left
    width="222"
  >
    <v-list nav density="compact">
      <template v-for="(item, i) in leftMenu" :key="i">
        <v-list-subheader v-if="item.header" :key="item.header">
          {{ item.header }}
        </v-list-subheader>

        <NavGroup v-else-if="item.children" :item="item" />

        <NavItem v-else :item="item" />
      </template>
    </v-list>

    <template #append>
      <!--      <v-no-ssr> -->
      <NavFooter />
      <!--      </v-no-ssr> -->
    </template>
  </v-navigation-drawer>
</template>

<style scoped lang="scss">
.todo {
  margin: auto;
}
</style>

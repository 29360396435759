<script setup lang="ts">
import { useTheme } from 'vuetify'
import FloatingButton from '~/components/main/customizer/FloatingButton.vue'
import { isDark } from '~/composables/utils'

const theme = useTheme()

// console.debug(`🌓 DARK: global.name='${theme.global.name.value}' ; current.dark=${theme.current.value.dark}`)
onMounted(() => { theme.global.name.value = isDark() ? 'dark' : 'light' }) // do not change to onBeforeMounted!
</script>

<template>
  <v-app>
    <MainHeader />
    <MainSidebar />

    <v-main class="">
      <v-container fluid class="page-wrapper left-circle">
        <slot />

        <FloatingButton />
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped lang="scss">
.page-wrapper {
  max-width: 1300px;
}

.left-circle::before {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: circle(30% at 7% 115%);
  content: '';
  background: linear-gradient(to right top, rgb(var(--v-theme-info)) 1%, rgb(var(--v-theme-secondary)) 18%);
  opacity: 0.2;
}

/*
.page-enter-active,
.page-leave-active {
  transition: opacity 0.5s;
}

.page-enter,
.page-leave-to {
  opacity: 0;
}
*/
</style>

<script setup lang="ts">
import { computed } from 'vue'
import ThemeSwitcher from '~/components/main/customizer/ThemeSwitcher.vue'
import MiniUserCard from '~/components/main/sidebar/profile/MiniUserCard.vue'
import { useUser } from '~/composables/auth/use-customer-info'
import { useSidebarStore } from '~/stores/sidebar'

const user = useUser()

const sidebar = useSidebarStore()

const showProfileCard = computed<boolean>(() => !!user.value && sidebar.sidebarLeftOpened)
</script>

<template>
  <section id="left-sidebar-bottom" class="px-3 pb-3">
    <v-no-ssr>
      <Transition name="fade">
        <ThemeSwitcher v-show="sidebar.sidebarLeftOpened" />
      </Transition>

      <Transition name="slide-fade">
        <MiniUserCard v-show="showProfileCard" />
      </Transition>
    </v-no-ssr>
  </section>
</template>

<style scoped lang="scss">
.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(-150px);
}

.fade-enter-active {
  transition: opacity 2.1s ease;
}

.fade-enter-from {
  opacity: 0;
}
</style>

<script setup lang="ts">
import type ListItem from '~/components/core/types/list'

const staticP = `${useRuntimeConfig().public.STATIC}/img/svgs`

const userprofile: ListItem[] = [
  {
    // icon: CurrencyDollarIcon,
    color: 'error',
    icon: `${staticP}/icon-account.svg`,
    title: 'My Profile',
    subtitle: 'Account Settings',
    href: '/user/profile',
  },
  {
    // icon: ShieldIcon,
    color: 'warning',
    icon: `${staticP}/icon-inbox.svg`,
    title: 'My Inbox',
    subtitle: 'Messages & Emails',
    href: '/user/inbox',
  },
  {
    // icon: CreditCardIcon,
    color: 'secondary',
    icon: `${staticP}/icon-tasks.svg`,
    title: 'My Tasks',
    subtitle: 'To-do and Daily Tasks',
    href: '/user/todo',
  },
]
</script>

<template>
  <v-list nav density="compact" color="primary">
    <v-list-item
      v-for="item in userprofile" :key="item.title" :value="item.title"
      lines="two"
      :title="item.title" :subtitle="item.subtitle"
      :to="item.href"
    >
      <template #prepend>
        <v-btn :color="item.color" flat variant="plain" icon elevation="0" class="mr-1">
          <!-- <component :is="item.icon" /> -->
          <v-img :src="item.icon" width="24" height="24" alt="User menu item avatar" />
        </v-btn>
      </template>
      <!--      <v-list-item-title v-text="item.title"></v-list-item-title> -->
    </v-list-item>
  </v-list>
</template>

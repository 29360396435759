<script setup lang="ts">
import { PowerIcon } from 'vue-tabler-icons'
import { useAuth } from '~/composables/auth/use-auth'
import { useUser } from '~/composables/auth/use-customer-info'
import { avatarUser } from '~/composables/images'

const { logout } = useAuth()
const user = useUser()
</script>

<template>
  <section>
    <v-sheet v-if="user" rounded="lg" color="lightsecondary" class="pa-2 d-flex align-center hide-menu">
      <v-avatar size="40">
        <v-img :src="avatarUser" alt="user picture" />
      </v-avatar>
      <div class="ml-4">
        <h4 class="mb-n1 text-h6 textprimary">
          {{ user!.nick }}
        </h4>
        <span class="text-subtitle-2 textsecondary">
          {{ user?.firstName }}
        </span>
      </div>
      <div class="ml-auto">
        <v-btn
          variant="text"
          icon
          rounded="md"
          color="primary"
          @click.stop="logout()"
        >
          <v-tooltip text="Logout" activator="parent" location="top" close-on-content-click>
            <template #activator>
              <PowerIcon />
            </template>
          </v-tooltip>
        </v-btn>
      </div>
    </v-sheet>
  </section>
</template>

<style scoped lang="scss">
.empty {
  line-height: normal;
}
</style>

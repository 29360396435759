<script setup lang="ts">
import { logoTextSrc } from '~/composables/images'

// const { showLogo = false } = defineProps<{ showLogo?: boolean }>() // TODO: if show narrow the only logo on smaller screen

const logoText = logoTextSrc()
</script>

<template>
  <NuxtLink to="/">
    <img :src="logoText" height="28" alt="Go to main">
  </NuxtLink>
</template>

<script setup lang="ts">
import { TiltShiftIcon } from 'vue-tabler-icons'
import { useSidebarStore } from '~/stores/sidebar'

const sidebar = useSidebarStore()
</script>

<template>
  <v-btn
    class="customizer-btn"
    variant="tonal"
    size="large"
    color="primary"
    icon
    @click.stop="sidebar.SIDEBAR_R_TOGGLE(!sidebar.sidebar_right)"
  >
    <v-icon>
      <TiltShiftIcon />
    </v-icon>
  </v-btn>
</template>

<style scoped lang="scss">
.customizer-btn {
  position: fixed !important;
  right: 5px !important;
  bottom: 5px !important;
  z-index: 2;
  animation-name: spin;
  animation-duration: 23s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>

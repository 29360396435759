<script setup lang="ts">
import type { SidebarMenu } from '~/components/main/sidebar/left-menu'
import { useUser } from '~/composables/auth/use-customer-info'
import { useSidebarStore } from '~/stores/sidebar'

const props = defineProps<{ item: SidebarMenu }>()

const user = useUser()

const sidebar = useSidebarStore()

const iconSize = 24
const nestedIconSize = 20

function getIconSize(): number {
  return props.item.nested ? nestedIconSize : iconSize
}
</script>

<template>
  <v-list-item
    :to="props.item.to" :value="props.item"
    :class="props.item.nested ? 'nested' : ''"
    nav color="primary"
  >
    <template #prepend>
      <component :is="props.item.icon" class="mr-3" :size="getIconSize()" />
    </template>

    <v-list-item-title :class="props.item.nested ? '' : 'text-subtitle-1'">
      {{ props.item.title }}
    </v-list-item-title>

    <v-list-item-subtitle v-if="item.subTitle" class="text-caption mt-n1 hide-menu">
      {{ item.subTitle }}
    </v-list-item-subtitle>

    <template v-if="item.chip && !!user" #append>
      <Transition name="bounce">
        <section v-show="sidebar.sidebarLeftOpened">
          <v-chip color="primary" size="small">
            {{ item.chip }}
          </v-chip>

          <v-chip v-if="item.chip2" class="ml-2" color="green" size="small">
            <template #prepend>
              <component :is="props.item.chip2Icon" size="15" class="mr-1" />
            </template>
            {{ item.chip2 }}
          </v-chip>
        </section>
      </Transition>
    </template>
  </v-list-item>
</template>

<style scoped lang="scss">
.nested {
  padding-inline-start: calc(1em + var(--indent-padding) / 10) !important;
}

.bounce-enter-active {
  animation: bounce-in 0.65s;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}
</style>

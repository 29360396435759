<script setup lang="ts">
import { LoginIcon } from 'vue-tabler-icons'
import { useDisplay } from 'vuetify'
import GuestRegisterBtn from '~/components/main/header/GuestRegisterBtn.vue'
import { useUser } from '~/composables/auth/use-customer-info'

const user = useUser()
const { smAndUp } = useDisplay()

function toLoginPage() {
  navigateTo('/login?t=0')
}
</script>

<template>
  <v-btn v-if="smAndUp && !user" variant="plain" :append-icon="LoginIcon" @click="toLoginPage()">
    Log in
  </v-btn>
  <GuestRegisterBtn v-if="!user" class="mr-3" />
</template>

<style scoped lang="scss">
.empty-for-linter {
  fill: transparent;
}
</style>

<script setup lang="ts">
import UserMenuBanner from '~/components/core/icons/profile/parts/UserMenuBanner.vue'
import UserMenuHeader from '~/components/core/icons/profile/parts/UserMenuHeader.vue'
import UserMenuList from '~/components/core/icons/profile/parts/UserMenuList.vue'
import { avatarUser } from '~/composables/images'
import { useAuth } from '~/composables/auth/use-auth'

const { logout } = useAuth()
</script>

<template>
  <v-menu width="340" :close-on-content-click="false">
    <template #activator="{ props }">
      <v-btn size="small" class="text-none" variant="text" v-bind="props" icon>
        <v-avatar cover size="30">
          <v-img :src="avatarUser" alt="Profile picture menu" />
        </v-avatar>
      </v-btn>
    </template>

    <v-card elevation="10" class="px-4">
      <UserMenuHeader />

      <v-divider />

      <UserMenuList />

      <UserMenuBanner />

      <v-card class="py-3 text-right">
        <v-btn variant="plain" @click.stop="logout()">
          Logout
        </v-btn>
      </v-card>
    </v-card>
  </v-menu>
</template>

<script setup lang="ts">
const staticP = `${useRuntimeConfig().public.STATIC}`
const bannerBg = `${staticP}/img/backgrounds/unlimited-bg.png`
</script>

<template>
  <v-card class="pa-5 mx-4 bg-lightprimary">
    <h5 class="text-h6 line-dense">
      <span class="text-accent">In</span>vite others to<br>
      colaba
      <span class="weight ml-1">pla<span class="glow" data-text="planet">net</span></span>
    </h5>
    <v-btn variant="outlined" color="accent" class="mt-3" elevation="8">
      Invite
    </v-btn>
    <img :src="bannerBg" alt="bg-img" class="right-pos-img">
  </v-card>
</template>

<style scoped lang="scss">
.right-pos-img {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.weight {
  letter-spacing: 0.1em;
}

.glow {
  text-shadow: 0 0 5px rgb(var(--v-theme-accent)), 0 0 15px rgb(var(--v-theme-accent)), 0 0 20px rgb(var(--v-theme-accent)), 0 0 40px rgb(var(--v-theme-accent)), 0 0 60px rgb(var(--v-theme-surface)), 0 0 10px rgb(var(--v-theme-primary)), 0 0 98px rgb(var(--v-theme-surface));
}
</style>

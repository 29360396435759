<script setup lang="ts">
import NotificationIcon from '~/components/core/icons/NotificationIcon.vue'
import ProfileIcon from '~/components/core/icons/profile/ProfileIcon.vue'
import { useUser } from '~/composables/auth/use-customer-info'

// import BellIcon from '~/components/core/icons/BellIcon.vue'
// import ChatIcon from '~/components/core/icons/ChatIcon.vue'

const user = useUser()
</script>

<template>
  <!--  <ChatIcon /> -->
  <!--  <BellIcon /> -->
  <NotificationIcon v-if="user" />

  <ProfileIcon v-if="user" />
</template>

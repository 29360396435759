<script setup lang="ts">
import { MailIcon } from 'vue-tabler-icons'
import { useUser } from '~/composables/auth/use-customer-info'
import { avatarUser } from '~/composables/images'

const user = useUser()
</script>

<template>
  <v-container>
    <h4 class="text-h6">
      My profile
    </h4>
    <div class="d-flex align-center pt-3">
      <v-avatar size="80">
        <v-img :src="avatarUser" width="80" alt="Profile picture" />
      </v-avatar>
      <section class="ml-3">
        <h5 class="line-dense text-subtitle-1 font-weight-medium">
          {{ user?.fio || user!.nick }}
        </h5>

        <h6 class="text-subtitle-2 font-weight-light text-medium-emphasis">
          {{ user?.profession || user?.specialisation || 'New kid 👀' }}
        </h6>
        <div class="d-flex align-center text-medium-emphasis">
          <MailIcon size="16" />
          <p class="ml-1 text-subtitle-2 font-weight-thin ">
            {{ user!.email }}
          </p>
        </div>
      </section>
    </div>
  </v-container>
</template>

<style scoped lang="scss">
.empty {
  line-height: normal;
}
</style>

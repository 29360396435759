<script setup lang="ts">
import { DoorEnterIcon } from 'vue-tabler-icons'
import { useDisplay } from 'vuetify'

const { xs } = useDisplay()

function toRegistrationPage() {
  navigateTo('/login?t=1')
}
</script>

<template>
  <v-btn v-if="xs" icon variant="plain" class="font-weight-bold" @click="toRegistrationPage()">
    <DoorEnterIcon class="wavy" />
  </v-btn>
  <v-btn v-else variant="tonal" color="accent" class="font-weight-bold" @click="toRegistrationPage()">
    <v-tooltip text="Become a colaber 🚀" activator="parent" location="top" :open-delay="1000" close-on-content-click>
      <template #activator>
        Get started
      </template>
    </v-tooltip>
  </v-btn>
</template>

<style scoped lang="scss">
.wavy {
  fill: transparent;
  stroke-dasharray: 25;
  stroke-dashoffset: 13;
  animation: dash-draw 9s forwards infinite;
}

@keyframes dash-draw {
  0% {
    color: rgb(var(--v-theme-accent));
    stroke-dashoffset: -10;
  }

  60% {
    color: rgb(var(--v-theme-primary));
    stroke-dashoffset: 13;
  }

  100% {
    color: rgb(var(--v-theme-accent));
    stroke-dashoffset: -10;
  }
}
</style>

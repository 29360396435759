<script setup lang="ts">
import type { SidebarMenu } from '~/components/main/sidebar/left-menu'
import NavItem from '~/components/main/sidebar/navigation/NavItem.vue'

const menuGroup = defineProps<{ item: SidebarMenu }>()
</script>

<template>
  <v-list-group v-if="menuGroup.item.children!.length > 0">
    <template #activator="{ props }">
      <NavItem v-bind="props" :item="menuGroup.item" />
    </template>
    <!--- Sub Item -->
    <NavItem
      v-for="(childMenuItem) in menuGroup.item.children!"
      :key="childMenuItem.title" :item="childMenuItem"
    />
  </v-list-group>
</template>

<script setup lang="ts">
import type ListItem from '~/components/core/types/list'

const quickLink: ListItem[] = [
  {
    title: 'Privacy 🍪',
    href: '/privacy',
  },
  {
    title: 'Terms & Conditions',
    href: '/tnc',
  },
  {
    title: 'Contact us',
    href: '/contact',
  },
  {
    title: 'Register Now',
    href: '/login?t=1',
  },
]
</script>

<template>
  <section class="px-5">
    <h5 class="text-h6 my-4">
      Quick Links
    </h5>

    <div v-for="(item, i) in quickLink" :key="i" class="py-2">
      <NuxtLink :to="item.href" class="text-decoration-none text-hover-primary font-weight-semibold text-subtitle-1">
        {{ item.title }}
      </NuxtLink>
    </div>
  </section>
</template>

<style scoped lang="scss">
.text-hover-primary {
  color: rgb(var(--v-theme-secondary));

  &:hover {
    color: rgb(var(--v-theme-primary));
  }
}

.link {
  color: rgb(var(--v-theme-accent));
  text-decoration: none;

  &:hover {
    color: rgb(var(--v-theme-primary));
  }
}
</style>
